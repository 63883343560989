import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { ApiService } from '../../shared/services/api.service';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { Observable } from 'rxjs';
import { URL_API } from '../utils/const';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Edital } from '../models/edital.model';
import * as moment from 'moment';
import { Block } from '../models/bloco';

@Injectable()
export class EvaluationInstrumentService {

  user: any = {};
  menu: any[] = [];
  menuDashboard: any[] = [];
  permissions: any[] = [];
  lasUrl: string;
  filters: any = {};

  constructor(private apiService: ApiService, private router: Router, private notification: NzNotificationService, private _http: HttpClient) {
    this.user = JSON.parse(localStorage.getItem('user'));
    this.filters = { filters: [{ model: '', filter: [{ 'perfil': this.user.perfil.co_perfil }] }], limit: -1 }
  }

  getPivotEspecialist(params): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.apiService.post(`especialista/advanced-search`, params)
        .subscribe(
          resp => resolve(resp),
          error => reject(error)
        );
    });
  }

  getEspecialist(co_especialista, co_classif_ped_edital): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.apiService.get(`especialista/${co_especialista}/${co_classif_ped_edital}/1`)
        .subscribe(
          resp => resolve(resp),
          error => reject(error)
        );
    });
  }

  getEspecialistByPedagogicalClassif(params): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.apiService.post('especialista/advanced-search', params)
        .subscribe(
          resp => resolve(resp),
          error => reject(error)
        );
    });
  }

  getPedagogicalClassifByEdital(params): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.apiService.post('edital/advanced-search', params)
        .subscribe(
          resp => resolve(resp),
          error => reject(error)
        );
    });
  }

  createInstrument(params): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.apiService.post(`instrumento-avaliacao`, params)
        .subscribe(
          resp => resolve(resp),
          error => reject(error)
        );
    });
  }

  editImportPermission(co_instrumento_avaliacao, st_permite_importacao): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.apiService.put(`instrumento-avaliacao/${co_instrumento_avaliacao}`, { 'st_permite_importacao': st_permite_importacao })
        .subscribe(
          resp => resolve(resp),
          error => reject(error)
        );
    });
  }

  finalizeInstrument(co_instrumento_avaliacao): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.apiService.put(`instrumento-avaliacao/${co_instrumento_avaliacao}`, { 'st_instrumento_avaliacao': 'F' })
        .subscribe(
          resp => resolve(resp),
          error => reject(error)
        );
    });
  }

  openInstrument(co_instrumento_avaliacao): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.apiService.put(`instrumento-avaliacao/${co_instrumento_avaliacao}`, { 'st_instrumento_avaliacao': 'N' })
        .subscribe(
          resp => resolve(resp),
          error => reject(error)
        );
    });
  }

  getInstrumentExistent(params): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.apiService.post(`instrumento-avaliacao/advanced-search`, params)
        .subscribe(
          resp => resolve(resp),
          error => reject(error)
        );
    });
  }

  getInstrumentExistentLazy(params: {co_edital, co_objeto, co_etapa_ensino?}): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.apiService.post(`instrumento-avaliacao/lazy`, params)
        .subscribe(
          resp => resolve(resp),
          error => reject(error)
        );
    });
  }

  getInstrumentExistentLoad(params: {co_edital, co_objeto, co_etapa_ensino?}): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.apiService.post(`instrumento-avaliacao/load`, params)
        .subscribe(
          resp => resolve(resp),
          error => reject(error)
        );
    });
  }

  getInstrument(co_instrumento_avaliacao): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.apiService.get(`instrumento-avaliacao/${co_instrumento_avaliacao}`)
        .subscribe(
          resp => resolve(resp),
          error => reject(error)
        );
    });
  }

  getInstrumentAttributes(co_instrumento_avaliacao): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.apiService.get(`instrumento-avaliacao/${co_instrumento_avaliacao}/atributos`)
        .subscribe(
          resp => resolve(resp),
          error => reject(error)
        );
    });
  }

  getCategories(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.apiService.post(`categoria/advanced-search/`, this.filters)
        .subscribe(
          resp => resolve(resp),
          error => reject(error),
        );
    });
  }

  getKnowledges(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.apiService.post(`area-conhecimento/advanced-search/`, this.filters)
        .subscribe(
          resp => resolve(resp),
          error => reject(error),
        );
    });
  }

  getComponents(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.apiService.post(`componente/advanced-search/`, this.filters)
        .subscribe(
          resp => resolve(resp),
          error => reject(error),
        );
    });
  }

  getThematicUnit(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.apiService.get(`unidade-tematica`)
        .subscribe(
          resp => resolve(resp),
          error => reject(error),
        );
    });
  }

  getKnowledgeObject(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.apiService.get(`objeto-conhecimento`)
        .subscribe(
          resp => resolve(resp),
          error => reject(error),
        );
    });
  }

  getExamples(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.apiService.get(`exemplar`)
        .subscribe(
          resp => resolve(resp),
          error => reject(error),
        );
    });
  }

  getFormats(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.apiService.get(`formato`)
        .subscribe(
          resp => resolve(resp),
          error => reject(error),
        );
    });
  }

  createBlock(params): Promise<any> {
    // params = this.cleanParam(params);
    return new Promise<any>((resolve, reject) => {
      this.apiService.post(`bloco`, params)
        .subscribe(
          resp => resolve(resp),
          error => reject(error),
        );
    });
  }

  updateBlock(co_bloco, params): Promise<any> {
    // params = this.cleanParam(params);
    return new Promise<any>((resolve, reject) => {
      this.apiService.put(`bloco/${co_bloco}`, params)
        .subscribe(
          resp => resolve(resp),
          error => reject(error),
        );
    });
  }

  duplicarBloco(co_bloco, filtro): Promise<any> {
    // params = this.cleanParam(params);
    return new Promise<any>((resolve, reject) => {
      this.apiService.post(`bloco/duplicar/${co_bloco}/${filtro.co_edital}/${filtro.co_etapa_ensino}/${filtro.co_objeto}`, {})
        .subscribe(
          resp => resolve(resp),
          error => reject(error),
        );
    });
  }

  mudarPosicao(co_bloco, orientacao, filtro): Promise<any> {
    // params = this.cleanParam(params);
    return new Promise<any>((resolve, reject) => {
      this.apiService.put(`bloco/mudar-posicao/${co_bloco}/${orientacao}/${filtro.co_edital}/${filtro.co_etapa_ensino}/${filtro.co_objeto}`, {})
        .subscribe(
          resp => resolve(resp),
          error => reject(error),
        );
    });
  }

  getBlock(co_bloco) {
    return new Promise<any>((resolve, reject) => {
      this.apiService.get(`bloco/${co_bloco}`)
        .subscribe(
          resp => resolve(resp),
          error => reject(error),
        );
    });
  }

  getSubblock(co_subbloco) {
    return new Promise<any>((resolve, reject) => {
      this.apiService.get(`sub-bloco/${co_subbloco}`)
        .subscribe(
          resp => resolve(resp),
          error => reject(error),
        );
    });
  }

  getGroup(co_grupo) {
    return new Promise<any>((resolve, reject) => {
      this.apiService.get(`grupo/${co_grupo}`)
        .subscribe(
          resp => resolve(resp),
          error => reject(error),
        );
    });
  }

  createSubblock(params): Promise<any> {
    // params = this.cleanParam(params);
    return new Promise<any>((resolve, reject) => {
      this.apiService.post(`sub-bloco`, params)
        .subscribe(
          resp => resolve(resp),
          error => reject(error),
        );
    });
  }

  updateSubblock(co_subbloco, params): Promise<any> {
    // params = this.cleanParam(params);
    return new Promise<any>((resolve, reject) => {
      this.apiService.put(`sub-bloco/${co_subbloco}`, params)
        .subscribe(
          resp => resolve(resp),
          error => reject(error),
        );
    });
  }

  createGroup(params): Promise<any> {
    // params = this.cleanParam(params);
    return new Promise<any>((resolve, reject) => {
      this.apiService.post(`grupo`, params)
        .subscribe(
          resp => resolve(resp),
          error => reject(error),
        );
    });
  }

  updateGroup(co_grupo, params): Promise<any> {
    // params = this.cleanParam(params);
    return new Promise<any>((resolve, reject) => {
      this.apiService.put(`grupo/${co_grupo}`, params)
        .subscribe(
          resp => resolve(resp),
          error => reject(error),
        );
    });
  }

  createQuestion(params): Promise<any> {
    params = this.cleanParam(params);
    return new Promise<any>((resolve, reject) => {
      this.apiService.post(`questao`, params)
        .subscribe(
          resp => resolve(resp),
          error => reject(error),
        );
    });
  }

  updateQuestion(co_questao, params): Promise<any> {
    params = this.cleanParam(params);
    return new Promise<any>((resolve, reject) => {
      this.apiService.put(`questao/${co_questao}`, params)
        .subscribe(
          resp => resolve(resp),
          error => reject(error),
        );
    });
  }

  getQuestaoItemsRespostaByQuestao(co_questao): Promise<any>{
    return new Promise<any>((resolve, reject) => {
      this.apiService.post('questao-item-resposta/advanced-search', 
      { 
        filters: [
          { model: "", filter: [ "co_questao", "=", co_questao] },
        ]
      }).subscribe(
        resp => resolve(resp),
        error => reject(error)
      );
    });
  }

  getKeyWords(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.apiService.get(`palavra-chave/`)
        .subscribe(
          resp => resolve(resp),
          error => reject(error),
        );
    });
  }

  getModelAnswer(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.apiService.get(`modelo-resposta/`)
        .subscribe(
          resp => resolve(resp),
          error => reject(error),
        );
    });
  }

  deleteInstrument(co_instrumento_avaliacao): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.apiService.delete(`instrumento-avaliacao/${co_instrumento_avaliacao}`)
        .subscribe(
          resp => {
            resolve(resp);
          },
          error => reject(error),
        );
    });
  }

  deleteBlock(co_bloco, filtro): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.apiService.delete(`bloco/${co_bloco}/${filtro.co_edital}/${filtro.co_etapa_ensino}/${filtro.co_objeto}`)
        .subscribe(
          resp => {
            this.notification.success('', `Bloco excluido com sucesso.`);
            resolve(resp);
          },
          error => reject(error),
        );
    });
  }

  deleteSubblock(co_subbloco): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.apiService.delete(`sub-bloco/${co_subbloco}`)
        .subscribe(
          resp => {
            this.notification.success('', `Sub-bloco excluido com sucesso.`);
            resolve(resp);
          },
          error => reject(error),
        );
    });
  }

  deleteGroup(co_grupo): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.apiService.delete(`grupo/${co_grupo}`)
        .subscribe(
          resp => {
            this.notification.success('', `Grupo excluido com sucesso.`);
            resolve(resp);
          },
          error => reject(error),
        );
    });
  }

  deleteQuestion(co_questao): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.apiService.delete(`questao/${co_questao}`)
        .subscribe(
          resp => {
            this.notification.success('', `Questão excluida com sucesso.`);
            resolve(resp);
          },
          error => reject(error),
        );
    });
  }

  createKeyWord(params): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.apiService.post('palavra-chave', params)
        .subscribe(
          resp => resolve(resp),
          error => reject(error),
        );
    });
  }

  cleanParam(param) {
    const propNames = Object.getOwnPropertyNames(param);
    for (let i = 0; i < propNames.length; i++) {
      let propName = propNames[i];
      if (param[propName] === null || param[propName] === undefined) {
        delete param[propName];
      }
    }

    return param;
  }

  getPDFFileInstrument(params: any): Observable<any> {
    let full_url_download_arquivo = `instrumento-avaliacao/previsualizar`;
    return this.apiService.relatorio(full_url_download_arquivo, params)
  }

  getDocXFileInstrument(params: any): Observable<any> {
    let full_url_download_arquivo = `instrumento-avaliacao/previsualizar-docx`;
    return this.apiService.relatorio(full_url_download_arquivo, params)
  }

  getEdital(co_edital): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.apiService.get(`edital/${co_edital}`)
        .subscribe(
          resp => resolve(resp),
          error => reject(error),
        );
    });
  }

  public getPeriodElaborationPhaseFormatDate(edital): string {
    const elaborationPhaseKey = 'elaboracao-ficha-avaliacao';
    let phasesEdital: any[] = edital.fases;

    let phaseElaboration = phasesEdital.find(phase => phase.fase.ds_chave == elaborationPhaseKey);

    if (phaseElaboration) {
      let formattedStartDate = moment(phaseElaboration.dt_inicio).format("DD/MM/YYYY");
      let formattedFinalDate = moment(phaseElaboration.dt_final).format("DD/MM/YYYY")
      return `${formattedStartDate} - ${formattedFinalDate}`;
    }else
      return null;
  }

  /**
   * 
   * @param instrument 
   * @param title 
   * @param nuOrdem 
   * @returns Novo bloco com valores padrões
   */
  public newBloco(instrument, title = null, nuOrdem = 1): Block{
    const block: Block = {
      co_bloco: null,
      ds_bloco: title || 'Bloco',
      st_bloco: 'CON',
      nu_ordem: nuOrdem,
      co_categoria: null,
      co_area_conhecimento: null,
      co_componente: null,
      ds_orientacoes: null,
      natureza_bloco: null,
      co_unidade_tematica: null,
      co_objeto_conhecimento: null,
      fl_exibir_bloco_rp: "S",
      fl_nao_se_aplica_massa: "N",
      fl_especifico: "N",
      co_instrumento_avaliacao: instrument.co_instrumento_avaliacao,
      st_instrumento: instrument.st_instrumento_avaliacao,
    };

    return block;
  }

  public getFiltrosSetup(coInstrumentoAvaliacao) {
    return new Promise<any>((resolve, reject) => {
        this.apiService.get(`instrumento-avaliacao/${coInstrumentoAvaliacao}/filtros-setup`)
          .subscribe(
            resp => resolve(resp),
            error => reject(error),
          );
      });
  }
}
